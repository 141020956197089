import React from 'react'
import styled from 'styled-components'

import {isBrowser} from '../helpers/window'
import {ErrorBoundary} from '../helpers/bugsnag'

const ErrorContainer = styled.div`
  padding: 40px;
  max-width: 400px;
  line-height: 1.5em;
  h2 {
    color: #c22;
    font-size: 1.5rem;
  }
  h3 {
    color: #444;
    font-size: 1.2rem;
  }
  a {
    color: #44a;
    font-weight: bold;
    text-decoration: underline;
  }
`

const FallbackComponent: React.FC = () => (
  <ErrorContainer>
    <h1 aria-hidden>🐞</h1>
    <h2>Une erreur est survenue.</h2>
    <h3>Essayez de recharger la page.</h3>
    <p>Si le problème persiste, mettez votre navigateur à jour.</p>
  </ErrorContainer>
)

const OutdatedBrowser: React.FC = () => (
  <ErrorContainer>
    <h1 aria-hidden>📺</h1>
    <h2>Votre navigateur est périmé.</h2>
    <p>
      Pour plus de sécurité et de fiabilité, et pour pouvoir naviguer sur ce site, veuillez{' '}
      <a href="https://browsehappy.com/">installer un navigateur récent.</a>
    </p>
  </ErrorContainer>
)

const Root: React.FC = ({children}) => {
  if (isBrowser() && navigator.userAgent.indexOf('MSIE') > 0) {
    return <OutdatedBrowser />
  }
  return <ErrorBoundary FallbackComponent={FallbackComponent}>{children}</ErrorBoundary>
}

export default Root
