const onClientEntry = () => {
  const css = document.createElement('link')
  css.rel = 'stylesheet'
  css.type = 'text/css'
  css.href = '//cloud.typenetwork.com/projects/3053/fontface.css/'
  document.head.appendChild(css)

  const polyfills = []

  if (typeof IntersectionObserver === 'undefined') {
    // IE will have to wait for this polyfill to be loaded first, because it's needed for gatsby-image
    polyfills.push(import('intersection-observer'))
  }
  if (typeof fetch === 'undefined') {
    polyfills.push(import('whatwg-fetch'))
  }
  return Promise.all(polyfills)
}

export default onClientEntry
