export const isBrowser = () => typeof window !== 'undefined'

export const getScroll = (): number => {
  if (isBrowser()) {
    return (
      window.pageYOffset ||
      ((document.documentElement || document.body.parentNode || document.body) as HTMLElement).scrollTop ||
      0
    )
  }
  return 0
}
