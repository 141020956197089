/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/layout/normalize.css'
import './src/layout/style.css'

import wrapper from './src/gatsby/wrapRootElement'
import entry from './src/gatsby/onClientEntry'

export const wrapRootElement = wrapper
export const onClientEntry = entry

// https://github.com/gatsbyjs/gatsby/issues/17914
// export const replaceHydrateFunction = () => (element, container, callback) => {
//   ReactDOM.render(element, container, callback)
// }
